import React from 'react';

import Layout from '../components/Layout';
import notFoundStyles from './../styles/not-found.module.scss';

const NotFound = () => {
  return (
    <Layout>
      <main className={notFoundStyles.container}>
        <span className={notFoundStyles.number}>404</span>
        <p className={notFoundStyles.text}>OOPS! Page not found</p>
      </main>
    </Layout>
  );
};

export default NotFound;